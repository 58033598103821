<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                wwindex {{perf}}
                <DataTable :value="wwindexes" class="p-datatable-sm" responsiveLayout="scroll" showGridlines stripedRows
                :lazy="true" :paginator="true" :rows="10" ref="dt" dataKey="id"
                :totalRecords="totalRecords" :loading="loading" @page="onPage($event)" @sort="onSort($event)"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[10,25,50,75,100]"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                selectionMode="single"
                v-model:filters="filters" @filter="onFilter($event)" filterDisplay="row">
                    <Column field="id" header="No" :sortable="true"></Column>
                    <Column field="price_date" header="日付" filterField="price_date" filterMatchMode="contains" ref="price_date" :sortable="true" :style="{'min-width': '10rem'}">
                        <template #filter="{filterModel,filterCallback}">
                            <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="日付" />
                        </template>
                    </Column>                    
                    <Column field="price_timestamp" header="時刻" :sortable="true"></Column>
                    <Column field="index_cd" header="指数コード" :sortable="true"></Column>
                    <Column field="index_geo" header="GEO" :sortable="true"></Column>
                    <Column field="index_name" header="指数名" filterField="index_name" filterMatchMode="contains" ref="index_name" :sortable="true" :style="{'min-width': '10rem'}">
                        <template #filter="{filterModel,filterCallback}">
                            <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="名称" />
                        </template>
                    </Column>                      
                    <Column field="high" header="高値" :sortable="true"></Column>
                    <Column field="low" header="安値" :sortable="true"></Column>
                    <Column field="open" header="始値" :sortable="true"></Column>               
                    <Column field="close" header="終値" :sortable="true"></Column>
                    <Column field="volume" header="出来高" :sortable="true"></Column>
                    <Column field="adj_close" header="調整後終値" :sortable="true"></Column>
                    <Column field="ago_today_diff" header="前日比" :sortable="true">
                      <template #body="slotProps">
                        <div :class="priceClass(slotProps.data)">
                          {{slotProps.data.ago_today_diff}}
                        </div>
                      </template>
                    </Column>
                    <Column field="ago_today_ratio" header="前日比%" :sortable="true">
                      <template #body="slotProps">
                        <div :class="priceClass(slotProps.data)">
                          {{slotProps.data.ago_today_ratio}}
                        </div>
                      </template>                    
                    </Column>
                    <Column field="info_source" header="情報源" :sortable="true"></Column>
                </DataTable>
            </div>
        </div>
    </div>
</template>

<script>
import WwindexService from '@/service/WwindexService';

export default {
    data() {
        return {
            loading: false,
            totalRecords: 0,
            wwindexes: null,
            lazyParams: {},
            filters1: {},
            filters: {
                'price_date': {value: '', matchMode: 'contains'},
                'index_name': {value: '', matchMode: 'contains'},
            },
            perf: null,
        }
    },
    wwindexService: null,
    created() {
        this.wwindexService = new WwindexService();
    },
    mounted() {
        this.loading = true;

        this.lazyParams = {
            first: 0,
            rows: this.$refs.dt.rows,
            sortField: "id",
            sortOrder: "-1",
            filters: this.filters
        };

        this.loadLazyData();
    },
    methods: {
        loadLazyData() {
            this.loading = true;

            setTimeout(() => {
                this.wwindexService.getAll(
                    {dt_params: JSON.stringify( this.lazyParams )})
                        .then(data => {
                            this.wwindexes = data.payload.data;
                            this.totalRecords = data.payload.total;
                            this.loading = false;
                            this.perf = data.perf;
                    }
                );
            }, Math.random() * 1000 + 250);
        },
        onPage(event) {
            this.lazyParams = event;
            this.loadLazyData();
        },
        onSort(event) {
            this.lazyParams = event;
            this.loadLazyData();
        },
        onFilter() {
            this.lazyParams.filters = this.filters;
            this.loadLazyData();
        },
        priceClass(data) {
          return [
            {
              'positive': data.ago_today_diff > 0,
              'negative': data.ago_today_diff < 0,
              'neutral': data.ago_today_diff == 0,
            }
          ];
        }
    }
}
</script>

<style scoped lang="scss">
@import '../assets/prod/styles/prices.scss';
</style>